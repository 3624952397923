.site-footer {
    background-color: #00006f;
    color: white;
    padding: 2rem 0;
    font-size: 0.875rem;
}
  
.footer-nav {
    text-align: center;
    margin-bottom: 1rem;
}

.footer-nav .nav-link {
    color: white;
    margin: 0 1rem;
    text-decoration: none;
    transition: color 0.3s ease;
    display: inline-block; /* Ensure links are inline for horizontal layout */
}

.footer-nav .nav-link:hover,
.footer-nav .nav-link:focus {
    color: #52c41a; /* Accent color for hover state */
}
  
@media (max-width: 768px) {
    .footer-nav {
        flex-direction: column;
        display: flex; /* Switch to flexbox for easy vertical alignment */
        align-items: center; /* Center align the items */
    }

    .footer-nav .nav-link {
        margin: 10px 0; /* Increase margin for vertical spacing between links */
    }
}

.copyright {
    text-align: center;
    margin-top: 1rem;
}
