.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00006f;
    padding: 1rem 2rem;
    color: white;
    position: relative; /* Ensures header is positioned relative to other content */
}

.main-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
}

.nav {
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease;
}

.nav a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
}

.hamburger {
    display: none; /* Keeps hamburger hidden on larger screens */
    cursor: pointer;
    position: absolute; /* Ensures hamburger does not push other elements */
    right: 20px; /* Positions to the right */
    top: 20px; /* Positions near the top of the header */
}

.hamburger .line {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s;
}

@media (max-width: 768px) {
    .nav {
        position: absolute;
        width: 100%; /* Ensures nav occupies the header entirely */
        left: 0; /* Aligns nav to the left edge */
        top: 100%; /* Positions it right below the header */
        background-color: #00006f;
        transform: translateX(-100%); /* Hides nav off-screen to the left */
        flex-direction: column; /* Stacks links vertically when visible */
    }

    .nav.open {
        transform: translateX(0); /* Brings the navigation into view */
    }

    .nav a {
        margin: 10px 0; /* Adds vertical spacing between links */
    }

    .hamburger {
        display: block; /* Only show hamburger on smaller screens */
    }
}
