html, body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
  /* This ensures there is no space at the top of the document */
}

.main-content {
  padding-top: /* Height of your header */;
}

u.underline {
  text-decoration: #52c41a underline;
  text-decoration-thickness: 3px;
}

  /* CSS for responsive iframe videos */
.responsive-video {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9; /* Ensures the video maintains a 16:9 aspect ratio */
}

@media (min-width: 768px) {
  .responsive-video {
    width: 640px; /* Fixed width for larger screens if needed */
    height: 360px; /* Fixed height for larger screens if needed */
  }
}

  .hero {
    background-image: url('../images/courage1.jpg'); /* Replace with the path to your image */
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 4rem 0;
  }
  
  .therapy-title h1 {
    color: black;
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  .therapy-title .tagline {
    color: black;
    font-size: 1.5rem;
  }

  .title-container {
    margin: auto; /* centering the box */
    width: 75%; /* takes up 75% of the screen width */
}
  
  /* Previous CSS */

.container {
  width: 80%;
  margin: auto;
  max-width: 1200px; /* Adjust max width as necessary */
}

.intro-section {
  padding: 2rem 0;
  background-color: #f9f9f9; /* A light background for the intro section */
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.section-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #333; /* Dark grey color for the text for readability */
  text-align: justify;
  margin-bottom: 1rem;
}

/* Previous CSS */

/* Phases section */
.phases-section {
  padding: 2rem 0;
  background-color: #fff; /* White background for this section */
}

.phases-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 1rem; /* Space between grid items */
}

.phase {
  background-color: #f9f9f9; /* Light background for each phase */
  padding: 1rem;
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); /* 3D box shadow */
  margin-bottom: 1rem; /* Space between phase boxes */
  border-top: 3px solid #52c41a; /* Accent color border at the top for visual appeal */
  transition: transform 0.3s ease-in-out; /* Smooth transition for interaction */
}

.phase-title {
  background: #f9f9f9;
  text-align: center;
  color: black;
  padding: 0.5rem 1rem;
  margin: 0 -1rem; /* Overlaps the padding to reach the edge */
  border-radius: 10px 10px 0 0; /* Rounded top corners */
}

.phase:hover {
  transform: translateY(-5px); /* Slight raise effect on hover */
}

.phase-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #333; /* Dark grey color */
}

/* Button and accent color */
.button {
  background-color: #0006F;
  color: white;
  /* ... other button styles ... */
}

.accent-color {
  color: #52c41a;
  /* Style for other elements that use the accent color */
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .phases-grid {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
}

/* ... (previous CSS) ... */

/* Testimonial section */
.testimonial-section {
  padding: 2rem 0;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  grid-gap: 1rem;
  margin-top: 1rem;
}

.testimonial {
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Softer shadow for a subtle 3D effect */
  border-top: 3px solid #52c41a; /* Accent color border */
  transition: transform 0.3s ease-in-out;
}

.testimonial:hover {
  transform: translateY(-5px);
}

.testimonial-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #333; /* Dark text for readability */
  text-align: justify;
}

.testimonial-author {
  text-align: right;
  font-style: italic;
  margin-top: 1rem;
  color: #0006F; /* Using the theme color for the author's name */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .testimonial-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for smaller screens */
  }
}

@media (max-width: 768px) {
  .testimonial-grid {
    grid-template-columns: 1fr; /* Single column on mobile screens */
  }
}

/* ... (previous CSS) ... */



/* Add this to your CSS file */

.feature-section {
  text-align: center;
  max-width: 800px; /* Adjust width as needed */
  margin: 0 auto;
  padding: 20px;
}

.feature-title {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.feature-list {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.feature-column {
  flex: 1;
}

.feature-description {
  margin-top: 20px;
  font-size: 1.1em;
}
