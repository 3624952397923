/* Contact.css */

.contact-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .contact-content {
    flex: 1;
    background-color: #f5f5f5;
    padding: 2rem 0;
    text-align: center;
  }
  
  .contact-form {
    max-width: 500px;
    margin: auto;
    padding: 1rem;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: white;
    border: 1px solid #ddd;
    padding: 0.5rem;
    border-radius: 4px;
  }
  
  .form-group input,
  .form-group textarea {
    border: none;
    outline: none;
    flex: 1;
    padding: 0.5rem;
  }
  
  .icon {
    margin-right: 0.5rem;
    color: #52c41a; /* accent color */
  }
  
  button {
    background-color: #0006FF; /* primary color */
    color: white;
    border: none;
    padding: 1rem 2rem;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056cc;
  }
  
  /* Footer CSS */
  .footer {
    background-color: #0006FF;
    color: white;
    text-align: center;
    padding: 1rem 0;
  }
  