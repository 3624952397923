/* Research.css */

.research-title {
    text-align: center;
    font-size: 2.5rem;
    padding-top: 10px;
}

.research-container {
    background-color: #f5f5f5; /* light gray background */
    min-height: 100vh; /* full height */
    padding: 2rem 0; /* spacing at the top and bottom */
  }
  
  .research-study {
    background-color: #fff; /* white background for the text box */
    margin: 1rem auto; /* centering the box */
    width: 75%; /* takes up 75% of the screen width */
    padding: 2rem; /* padding inside the text box */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow */
    border-radius: 8px; /* rounded corners */
  }
  
  .research-study h2 {
    color: #0006F; /* using the primary color for the title */
    margin-bottom: 1rem;
  }
  
  .research-study p {
    color: #333; /* darker text for readability */
    line-height: 1.6; /* spacing out the lines for readability */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .research-study {
      width: 90%; /* more width on smaller screens */
      padding: 1rem; /* less padding on smaller screens */
    }
  }
  