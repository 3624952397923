/* Training.css */
.workshop-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 1rem auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px; /* Uniform padding around the box */
    max-width: 50%;
  }
  
  .workshop-header {
    margin-bottom: 20px; /* Space between the header and the text */
    margin-left: 10px;
  }
  
  .workshop-details {
    position: relative; /* Ensures content positions relative to this container */
  }
  
  .float-video {
    float: right; /* Float the video to the right */
    margin-left: 20px; /* Space between the text and the video */
    margin-bottom: 10px;
    width: 40%; /* Adjust the width as necessary */
    height: auto; /* Maintain aspect ratio */
    aspect-ratio: 16 / 9;
  }
  
  p {
    margin-left: 0; /* Ensures paragraph text aligns with the header */
    margin-right: 20px; /* Ensures consistent margin on the right */
  }
  
  @media (max-width: 768px) {
    .float-video {
      float: none; /* No float on small screens */
      width: 100%; /* Full width on small screens */
      margin-bottom: 20px; /* Space below the video */
    }
    p {
      margin-right: 0; /* Reset margin for small screens for full-width text */
    }
  }
  