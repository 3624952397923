/* Training.css */

html, body {
    margin: 0;
    padding: 0;
    min-height: 100%; /* Ensure full height */
  }
  
  .training-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
  }
  
  .training-content {
    flex: 1; /* Grow to occupy the space */
    background-color: #f5f5f5; /* Light gray background for the content */
  }
  
  .training-header {
    text-align: center;
    padding-top: 80px; /* Assuming header height, adjust as needed */
  }
  
  .training-intro {
    text-align: center;
    margin: 2rem;
  }
  
  .workshop-container {
    padding: 2rem 0;
  }
  
  .workshop-box {
    display: flex;
    justify-content: space-between; /* Ensures space between the two main divs */
    align-items: stretch; /* Makes sure both divs stretch to take equal height */
    background-color: #fff;
    margin: 1rem auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px; /* Adds some padding inside the box */
    max-width: 300px;
  }
  
  .workshop-details,
  .workshop-payment {
    flex: 1; /* Allows each side to take up equal space */
    margin: 0 10px; /* Adds margin to both sides for spacing */
  }
  
  .workshop-payment {
    margin-left: 20px; /* Adds left margin to workshop-payment */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .workshop-box {
      flex-direction: column; /* Stack the columns on smaller screens */
    }
  
    .workshop-details,
    .workshop-payment {
      margin: 0; /* Reset margins for smaller screens */
      margin-bottom: 20px; /* Adds margin to the bottom for spacing */
    }
  
    .workshop-payment {
      margin-left: 0; /* Resets left margin for workshop-payment on smaller screens */
    }
  }
  
  .workshop-bullets {
    list-style: none;
    padding: 0;
  }
  
  .workshop-bullets li {
    margin-bottom: 0.5rem;
  }
  
  .checkmark {
    color: #52c41a;
    margin-right: 0.5rem;
  }

  .title-container {
    max-width: 800px; /* Adjust as needed */
    margin: auto;
    padding: 20px;
  }
  
  .float-video {
    float: right; /* Float the video to the right */
    margin-left: 20px; /* Space between the text and the video */
    width: 50%; /* Adjust the width as necessary */
    height: auto; /* Maintain aspect ratio */
    aspect-ratio: 16 / 9;
  }
  
  .training-intro {
    text-align: left;
    margin: 2rem 0;
  }
  
  @media (max-width: 768px) {
    .float-video {
      float: none; /* No float on small screens */
      width: 100%; /* Full width on small screens */
      margin: 0 0 20px 0; /* Space below the video */
    }
  }
  
  
 
  